import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import get from 'lodash/get'
import Img from 'gatsby-image'
import landingStyles from './landing-page.module.css'
import LayoutBare from '../components/layout-bare'

class LandingPageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.mdx')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <LayoutBare location={this.props.location} >
        <div className="main">
          <Helmet title={`${page.frontmatter.title} | ${siteTitle}`} defer={false}/>
          <div className="wrapper">
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </div>
      </LayoutBare>
    )
  }
}

export default LandingPageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
        siteMetadata {
          title
        }
      }
      mdx(frontmatter: { slug: { eq: $slug } }) {
        body
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
  }
`
